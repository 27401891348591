import React, { useState, useEffect } from 'react';
import datasetLinks from './datasetLinks.json';
import './datasetInfo.css';

const DatasetInfo = ({ datasetKey }) => {
  const [datasetInfo, setDatasetInfo] = useState(null);

  useEffect(() => {
    if (datasetKey in datasetLinks) {
      setDatasetInfo(datasetLinks[datasetKey]);
    }
  }, [datasetKey]);

  if (!datasetInfo) {
    return null;
  }

  const renderTextWithLink = (text, link, keyword) => {
    if (!text || !link || !keyword) return text;
    const parts = text.split(keyword);
    return (
      <>
        {parts[0]}
        <a href={link} target="_blank" rel="noopener noreferrer">
          {keyword}
        </a>
        {parts[1]}
      </>
    );
  };

  return (
    <div className="dataset-info">
      <p className="overview-title">
        <strong>{datasetInfo.title}</strong>
      </p>
      <p className="overview-description">
        {renderTextWithLink(datasetInfo.website_text, datasetInfo.website_link, datasetInfo.website_link_keyword)}
      </p>
      <p className="overview-description">
        {renderTextWithLink(datasetInfo.list_of_variables_text, datasetInfo.list_of_variables, datasetInfo.list_of_variables_link_keyword)}
      </p>
    </div>
  );
};

export default DatasetInfo;
