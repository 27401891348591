import React, { Component } from "react";
import Datetime from "react-datetime";
import { parseDateRange, setDate, parseDate } from "../../functions";
import "react-datetime/css/react-datetime.css";
import "../../App.css";

class ProgressiveDropdowns extends Component {
  state = {
    message: "",
  };

  showOverview = () => {
    const { datasetLinks, dataset } = this.props;
    if (dataset && datasetLinks && datasetLinks[dataset]) {
      window.open(datasetLinks[dataset].website_link, "_blank");
    } else {
      alert("Overview link not available for this dataset.");
    }
  };

  handleStartDateChange = (moment, dateRange) => {
    var { files, setFiles } = this.props;
    files["start"] = setDate(moment.toDate(), dateRange.len);
    setFiles(files);
    setTimeout(() => {
      document.getElementById("endDate").querySelector("input").focus();
    }, 100);
  };
  handleEndDateChange = (moment, dateRange) => {
    var { files, setFiles, setValid } = this.props;
    files["end"] = setDate(moment.toDate(), dateRange.len);
    setFiles(files);
    setValid(true);
  };
  setFiles = (event) => {
    var { files, setFiles, setValid, setCopied, metadata } = this.props;
    files[event.target.id] = event.target.value;
    var index = metadata.labels.indexOf(event.target.id);
    for (let i = index + 1; i < metadata.labels.length; i++) {
      if (metadata.labels[i] in files) delete files[metadata.labels[i]];
    }
    if ("start" in files) delete files["start"];
    if ("end" in files) delete files["end"];
    setFiles(files);
    setValid(false);
    setCopied(false);
  };

  validDate = (current, dateRange, end) => {
    var { files } = this.props;
    var min = dateRange.min;
    if ("start" in files && end) {
      min = parseDate(files["start"]);
    }
    if (current >= min && current <= dateRange.max) {
      return true;
    } else {
      return false;
    }
  };
  componentDidUpdate() {
    var { metadata, files, setValid } = this.props;
    if ("data" in metadata) {
      var dropdowns = [];
      for (let i = 0; i < metadata.labels.length; i++) {
        let value = JSON.parse(JSON.stringify(metadata.data));
        for (let j = 0; j < i; j++) {
          value = value[files[metadata.labels[j]]];
        }
        let options = Object.keys(value);
        dropdowns.push({ label: metadata.labels[i], options });
        if (metadata.labels[i] in files) {
          if (
            i === metadata.labels.length - 1 &&
            !("date_range" in value[files[metadata.labels[i]]])
          ) {
            setValid(true);
          }
        } else {
          break;
        }
      }
    }
  }
  render() {
    var { message } = this.state;
    var { metadata, files } = this.props;
    var dateRange = false;
    if ("data" in metadata) {
      var dropdowns = [];
      for (let i = 0; i < metadata.labels.length; i++) {
        let value = JSON.parse(JSON.stringify(metadata.data));
        for (let j = 0; j < i; j++) {
          value = value[files[metadata.labels[j]]];
        }
        let options = Object.keys(value);
        dropdowns.push({ label: metadata.labels[i], options });
        if (metadata.labels[i] in files) {
          if (
            i === metadata.labels.length - 1 &&
            "date_range" in value[files[metadata.labels[i]]]
          ) {
            dateRange = parseDateRange(
              value[files[metadata.labels[i]]]["date_range"]
            );
          }
        } else {
          break;
        }
      }
      var dateFormat = "LL";
      if (dateRange) {
        if (dateRange.len === 6) {
          dateFormat = "YYYY-MM";
        } else if (dateRange.len === 4) {
          dateFormat = "YYYY";
        }
      }

      return (
        <div className="progressive-dropdowns">
          {dropdowns.map((d) => {
            return (
              <div className="dropdown" key={d.label}>
                <div className="label">{d.label}</div>
                <select
                  onChange={this.setFiles}
                  id={d.label}
                  value={files[d.label] === undefined ? "" : files[d.label]}
                >
                  <option value="">Select an option</option>
                  {d.options.map((o) => (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ))}
                </select>
              </div>
            );
          })}
          {dateRange && (
            <div className="dates">
              <div className="date-input">
                <div className="label">Start</div>
                <Datetime
                  value={parseDate(files["start"])}
                  initialViewMode="years"
                  timeFormat={false}
                  dateFormat={dateFormat}
                  onChange={(event) =>
                    this.handleStartDateChange(event, dateRange)
                  }
                  closeOnSelect={true}
                  isValidDate={(event) => this.validDate(event, dateRange, false)}
                />
              </div>
              <div className="date-input" id="endDate">
                <div className="label">End</div>
                <Datetime
                  value={parseDate(files["end"])}
                  initialViewMode="years"
                  timeFormat={false}
                  dateFormat={dateFormat}
                  onChange={(event) =>
                    this.handleEndDateChange(event, dateRange)
                  }
                  closeOnSelect={true}
                  isValidDate={(event) => this.validDate(event, dateRange, true)}
                />
              </div>
              <div>
                Data available from {setDate(dateRange.min, dateRange.len)} to{" "}
                {setDate(dateRange.max, dateRange.len)}
              </div>
            </div>
          )}
          <div className="message">{message}</div>
        </div>
      );
    }
  }
}

export default ProgressiveDropdowns;
