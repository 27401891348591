import React, { Component } from "react";
import axios from "axios";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import SelectArea from "./components/selectarea/selectarea";
import ProgressiveDropdowns from "./components/progressivedropdowns/progressivedropdowns";
import Modal from "./components/modal/Modal";
import Instructions from "./components/instructions/Instructions";
import DatasetInfo from "./components/datasetinfo/DatasetInfo";
import datasetLinks from "./components/datasetinfo/datasetLinks.json";
import "./App.css";

class App extends Component {
  state = {
    selectGrid: false,
    lat: 47.38,
    lon: 8.55,
    minLat: 45.66,
    minLon: 5.81,
    maxLat: 47.85,
    maxLon: 10.64,
    datasets: [],
    dataset: false,
    metadata: {},
    files: {},
    valid: false,
    copied: false,
    showModal: false,
  };

  toggleselectGrid = () => {
    this.setState({ selectGrid: !this.state.selectGrid });
  };

  onChangeValue = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  updateRectangle = (minLat, minLon, maxLat, maxLon) => {
    this.setState({ minLat, minLon, maxLat, maxLon });
  };

  updatePoint = (lat, lon) => {
    this.setState({ lat, lon });
  };

  setDataset = async (dataset) => {
    if (dataset !== this.state.dataset) {
      document.getElementById("download").style.display = "block";
      this.setState(
        { metadata: {}, files: {}, valid: false, copied: false },
        async () => {
          var { data: metadata } = await axios.get(`https://zephyr-c2sm.s3.eu-central-1.amazonaws.com/file_tree_${dataset}_noindent.json`);
          var files = { dataset };

          this.setState({ metadata, dataset, files }, () => {
            document.getElementById("download").style.display = "none";
          });
        }
      );
    }
  };

  setFiles = (files) => {
    this.setState({ files });
  };

  setValid = (valid) => {
    if (this.state.valid !== valid) {
      this.setState({ valid });
    }
  };

  setCopied = (copied) => {
    this.setState({ copied });
  };

  copyToClipboard = () => {
    if (this.state.valid) {
      navigator.clipboard.writeText(
        JSON.stringify(this.createRequest(), null, 2)
      );
      this.setState({ copied: true, showModal: true });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false, copied: false });
  };

  createRequest = () => {
    var { selectGrid, lat, lon, minLat, minLon, maxLat, maxLon, files } =
      this.state;
    var request = { files_query_options: files };
    if (selectGrid) {
      request["extraction_query_options"] = {
        selected_option: "nearest_grid_point",
        latitude: lat,
        longitude: lon,
      };
    } else {
      request["extraction_query_options"] = {
        selected_option: "rectangular_clipping",
        min_latitude: minLat,
        max_latitude: maxLat,
        min_longitude: minLon,
        max_longitude: maxLon,
      };
    }
    return request;
  };

  async componentDidMount() {
    var { data: datasets } = await axios.get(`https://zephyr-c2sm.s3.eu-central-1.amazonaws.com/datasets.json`);
    this.setState({ datasets });
  }

  render() {
    var { dataset, datasets, metadata, files, valid, copied, showModal } =
      this.state;
    var request = this.createRequest();
    const message = `Request copied to clipboard!<br>Follow this <a href="https://github.com/C2SM/zephyr-request/issues/new/choose" target="_blank" rel="noopener noreferrer">link</a> to submit your request through the GitHub Issue Data Request Template.`;

    return (
      <div className="main">
        <Header />
        <Instructions />
        <div className="content">
          <div className="section">Data Selection</div>
          <div className="datasets">
            {datasets.map((d) => (
              <div
                className={d.key === dataset ? "dataset active" : "dataset"}
                onClick={() => this.setDataset(d.key)}
                key={d.key}
              >
                {d.name}
              </div>
            ))}
          </div>
          <DatasetInfo datasetKey={dataset} />
          <div id="download" className="download">
            Downloading...
          </div>
          <ProgressiveDropdowns
            metadata={metadata}
            files={files}
            setFiles={this.setFiles}
            setValid={this.setValid}
            setCopied={this.setCopied}
            datasetLinks={datasetLinks}
            dataset={dataset}
          />
          <div className="section">Area Selection</div>
          <SelectArea
            {...this.state}
            onChangeValue={this.onChangeValue}
            toggleselectGrid={this.toggleselectGrid}
            updatePoint={this.updatePoint}
            updateRectangle={this.updateRectangle}
          />
          <div className="section">Inspect Your Request</div>
          <div className={valid ? "request-box" : "request-box invalid"}>
            <div className="valid">
              {valid ? "Valid Request: Copy and submit your request." : "Invalid Request: Please select a dataset and all necessary dropdown options."}
            </div>
            <pre>{JSON.stringify(request, null, 2)}</pre>
          </div>
          <div
            className={
              valid ? (copied ? "copy clicked" : "copy enabled") : "copy"
            }
            onClick={this.copyToClipboard}
          >
            {copied ? "Copied" : "Click to copy"}
          </div>
        </div>
        <Footer />
        {showModal && (
          <Modal message={message} closeModal={this.closeModal} />
        )}
      </div>
    );
  }
}

export default App;
