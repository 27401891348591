import React, { Component } from "react";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "./css/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

class SelectMap extends Component {
  drawRectangle = () => {
    var drawRectangle = new L.Draw.Rectangle(this.map, {
      shapeOptions: {
        color: "#3388ff",
        weight: 2,
      },
    });
    drawRectangle.enable();
  };
  drawMarker = () => {
    var drawMarker = new L.Draw.Marker(this.map, { icon: DefaultIcon });
    drawMarker.enable();
  };
  plotItems = () => {
    var { marker, lat, lon, minLat, minLon, maxLat, maxLon } = this.props;
    this.drawnItems.clearLayers();
    if (marker) {
      L.marker([lat, lon]).addTo(this.drawnItems);
    } else {
      L.rectangle(
        L.latLngBounds([
          [minLat, minLon],
          [maxLat, maxLon],
        ]),
        {
          color: "#3388ff",
          weight: 1,
        }
      ).addTo(this.drawnItems);
    }
  };
  componentDidUpdate() {
    this.plotItems();
  }
  async componentDidMount() {
    var { updatePoint, updateRectangle } = this.props;
    var center = [46.9, 8.2];
    var zoom = 6;
    this.map = L.map("map", {
      preferCanvas: true,
      center: center,
      zoom: zoom,
      minZoom: 3,
      maxZoom: 17,
      showCursorLocation: true,
      zoomAnimation: true,
    });
    this.map.doubleClickZoom.disable();
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    const round = (value, decimals) => {
      return Math.round(value * 10 ** decimals) / 10 ** decimals;
    };
    this.drawnItems = new L.FeatureGroup();
    var drawnItems = this.drawnItems;
    this.map.addLayer(this.drawnItems);
    this.plotItems();
    this.map.on("draw:created", function (e) {
      var type = e.layerType,
        layer = e.layer;
      if (type === "rectangle") {
        drawnItems.clearLayers();
        drawnItems.addLayer(layer);
        var bounds = layer.getBounds();
        var ne = bounds._northEast;
        var sw = bounds._southWest;
        updateRectangle(
          round(sw.lat, 4),
          round(sw.lng, 4),
          round(ne.lat, 4),
          round(ne.lng, 4)
        );
      } else if (type === "marker") {
        drawnItems.clearLayers();
        drawnItems.addLayer(layer);
        updatePoint(round(layer._latlng.lat, 4), round(layer._latlng.lng, 4));
      }
    });
  }

  render() {
    var { marker } = this.props;
    return (
      <React.Fragment>
        <div id="map" className="leaflet-map">
          <button
            onClick={marker ? this.drawMarker : this.drawRectangle}
            title={marker ? "Select point" : "Draw box"}
          >
            {marker ? "Select point" : "Draw box"}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SelectMap;
