export const parseDateRange = (dateRangeStr) => {
  const [startStr, endStr] = dateRangeStr.split("-");
  const min = parseDate(startStr);
  const max = parseDate(endStr);
  return { min, max, len: startStr.length };
};

export const setDate = (date, len) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  if (len === 4) {
    return `${year}`;
  } else if (len === 6) {
    return `${year}${month}`;
  } else if (len === 8) {
    return `${year}${month}${day}`;
  } else if (len === 10) {
    return `${year}${month}${day}00`;
  } else if (len === 12) {
    return `${year}${month}${day}0000`;
  } else {
    return false;
  }
};

export const parseDate = (dateStr) => {
  let year,
    month = 1,
    day = 1;
  if (dateStr) {
    if (dateStr.length === 4) {
      year = parseInt(dateStr.slice(0, 4), 10);
    } else if (dateStr.length === 6) {
      year = parseInt(dateStr.slice(0, 4), 10);
      month = parseInt(dateStr.slice(4, 6), 10);
    } else if (dateStr.length >= 8) {
      year = parseInt(dateStr.slice(0, 4), 10);
      month = parseInt(dateStr.slice(4, 6), 10);
      day = parseInt(dateStr.slice(6, 8), 10);
    }
    return new Date(year, month - 1, day);
  } else {
    return undefined;
  }
};
