import React, { Component } from "react";
import c2sm from "../../img/c2sm.png";
import eth from "../../img/eth.png";
import eawag from "../../img/eawag.png";
import "./footer.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <div className="logos">
          <div className="logo eth">
            <a href="https://ethz.ch/de.html" target="_blank" rel="noopener noreferrer">
              <img src={eth} alt="ETH" />
            </a>
          </div>
          <div className="logo c2sm">
            <a href="https://c2sm.ethz.ch" target="_blank" rel="noopener noreferrer">
              <img src={c2sm} alt="C2SM" />
            </a>
          </div>
          <div className="logo eawag">
            <a href="https://www.eawag.ch/de/" target="_blank" rel="noopener noreferrer">
              <img src={eawag} alt="Eawag" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;